* {
  box-sizing: border-box;
}

:root {
  --main-bg-color: #aaddd9;
  --main-text-color: #187035;
  --main-secondary-text-color: #f4752c;
  --main-secondary-color: #589ca7;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 100%;
  font-family: "Work Sans", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

/* Global styles */

.container-fluid {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 700;
  font-size: 40px;
}

h2 {
  font-weight: 500;
  font-size: 36px;
}

p {
  font-weight: 400;
}

.card {
  height: 350px;
}

/* Header styles */

.navigation-bar {
  display: flex;
  justify-content: space-between;
}

header.app-header {
  width: 100%;
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
}

.app-link {
  text-decoration: none;
  color: var(--main-text-color);
  font-size: 46px;
  font-weight: bold;
  line-height: 1em;
  letter-spacing: 1px;
}

.app-link:hover {
  text-decoration: none;
  color: var(--main-text-color);
}

#unique {
  color: var(--main-text-color);
}

.socialMedia {
  display: flex;
  align-items: center;
}

.socialMedia a {
  margin: 10px;
}

i.fab {
  color: #fff;
}

i.fab:hover {
  color: var(--main-bg-color);
}

.call-to-action-container {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: space-between;
}

.call-to-action-image {
  display: flex;
  justify-content: center;
}

.call-to-action-image img {
  width: 500px;
}

.menu-items li {
  cursor: pointer;
  color: #fff;
  display: inline-block;
  padding: 40px 20px 0 20px;
  font-size: 30px;
  font-weight: bold;
}

.menu-items li:hover {
  color: var(--main-text-color);
}

/* LOCATIONS STYLES */

.locationsContainer #pushTop {
  margin-top: 100px;
}

/* Footer styles */

footer {
  background-color: var(--main-bg-color);
}

footer p {
  color: var(--main-text-color);
}

/* Media Queries */

@media screen and (max-width: 1100px) {
  .call-to-action-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 40px 0;
  }

  .call-to-action-image {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .navigation-bar {
    display: block;
    text-align: center;
  }
}
